import * as React from "react";
import {
  useIsAdmin,
  useIsAdminContract,
  useIsOperator,
  useIsSuperViewer,
} from "utils/user-role";

import NoPermissionComponent from "containers/noPermissionPage/NoPermission";
import { CursorPagination, useGetMachineCashQuery } from "gql/generated";
import { ColumnFiltersState, SortingState, TableV2 } from "components/tableV2";
import { useTranslation } from "react-i18next";
import { RowPerPageValue } from "components/tableV2/types/tableTypes";
import { mapColumnFilterToMachineMonitorFilter } from "containers/machinesV2/views/monitor/pages/overview/utils/mapColumnFilterToMachineMonitorFilter";
import {
  mapSortStateToSortFilter,
  generateCashColumnConfig,
  mapDataToTable,
} from "./utils";

function Cash(): JSX.Element {
  const canViewContent =
    useIsAdmin() ||
    useIsAdminContract() ||
    useIsOperator() ||
    useIsSuperViewer();
  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  const { t } = useTranslation("machine");
  const [rowPerPage, setRowPerPage] = React.useState<RowPerPageValue>(20);
  const [sorting, setSorting] = React.useState<SortingState>(undefined);
  const [pagination, setPagination] = React.useState<
    Omit<CursorPagination, "sort">
  >({
    first: rowPerPage,
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const initialPagination: CursorPagination = {
    first: rowPerPage,
  };
  const { data, isLoading } = useGetMachineCashQuery({
    pagination: {
      ...pagination,
    },
    sort: mapSortStateToSortFilter(sorting),
    filter: mapColumnFilterToMachineMonitorFilter(columnFilters),
  });
  const resetPagination = (val) => setPagination({ first: val });
  const columns = React.useMemo(() => {
    return generateCashColumnConfig(t);
  }, [data, columnFilters]);
  const dataRows = React.useMemo(() => mapDataToTable(data), [data]);
  return (
    <TableV2
      tableName="Cash Table"
      columns={columns}
      isLoading={isLoading}
      data={dataRows}
      sorting={sorting}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      onSortingChange={(state) => {
        setSorting(state);
        resetPagination(rowPerPage);
      }}
      pagination={{
        rowPerPage: rowPerPage,
        onRowPerPageChange: (val) => {
          setRowPerPage(val);
          resetPagination(val);
        },
        hasNextPage: data?.machineMonitors?.pageInfo?.hasNextPage || false,
        hasPreviousPage:
          data?.machineMonitors?.pageInfo?.hasPreviousPage || false,
        onNextPage: () =>
          setPagination(() => ({
            after: data?.machineMonitors?.pageInfo?.endCursor,
            first: rowPerPage,
            last: undefined,
            before: undefined,
          })),
        onPreviousPage: () =>
          setPagination(() => ({
            first: undefined,
            after: undefined,
            last: rowPerPage,
            before: data?.machineMonitors?.pageInfo?.startCursor,
          })),
        resetPagination: () => setPagination(initialPagination),
      }}
      defaultColumnSize={100}
    />
  );
}

export default Cash;
