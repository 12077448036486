import Typography from "components/Typography/Typography";
import * as React from "react";
import { Skeleton } from "@mui/material";
import Button from "components/Button/Button";
import {
  CreateOrEditInventoryModal,
  EditInventoryModalIntialValues,
  UnitOfMeasurement,
} from "./CreateOrEditInventoryModal";
import { CATEGORY } from "../Warehouses/constants";
import { useGetProductByIdFromCatalog } from "./hooks/use-get-product-catalog";
import { InventoryIdViewTable } from "./InventoryIdViewTable";
import {
  PreventTextOverflow,
  TextDotSeparator,
} from "../../components/TextDotSeparator";
import { useInventoryIdFromURL } from "./hooks/use-inventory-id-from-url";
import { InventoryImage } from "../../components/InventoryImage";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "components/Fallback";
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsForthCKUser,
  useIsMalaysianUser,
  useIsSingaporeanUser,
  useIsAustralianUser,
  useIsUnitedArabEmiratesUser,
} from "utils/user-role";

function useCanEditInventory() {
  const canEdit =
    (useIsForthCKUser() || useIsMalaysianUser() || useIsSingaporeanUser() || useIsAustralianUser() || useIsUnitedArabEmiratesUser() ) &&
    (useIsAdmin() || useIsClerk() || useIsAdminContract());
  return canEdit;
}

export function EditInventoryButton(props: {
  initialValue: EditInventoryModalIntialValues;
}) {
  const canEdit = useCanEditInventory();
  const [isOpen, setIsOpen] = React.useState(false);

  if (!canEdit) {
    return null;
  }

  return (
    <>
      <Button
        nowrap
        type="secondary"
        onClick={() => setIsOpen(true)}
        iconName="PencilCreate"
        style={{
          maxWidth: "100%",
        }}
      >
        label_edit_inventory_detail
      </Button>
      {isOpen ? (
        <CreateOrEditInventoryModal
          initialValues={props.initialValue}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
}

export function InventoryIdView() {
  const inventoryId = useInventoryIdFromURL();
  const { data, isLoading } = useGetProductByIdFromCatalog(inventoryId);
  const [editInventoryData, setEditInventoryData] =
    React.useState<EditInventoryModalIntialValues>();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          padding: "23px 29px",
          display: "flex",
          gap: "40px",
          borderBottom: "1px solid rgba(0,0,0,0.12)",
        }}
      >
        <InventoryImage
          width="148px"
          height="148px"
          imgUrl={data?.productImage}
          isLoading={isLoading}
        />
        <div
          style={{
            flex: "1 1 0",
            minWidth: "0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ lineHeight: 0.8 }}>
            {isLoading ? (
              <Skeleton height={"17px"} style={{ transform: "scale(1,1)" }} />
            ) : (
              <Typography type="headline-6">{data.productName}</Typography>
            )}
          </div>

          <div style={{ display: "flex", gap: "26px", marginTop: "9px" }}>
            <div style={{ flexBasis: "206px", minWidth: "0px" }}>
              {isLoading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <>
                  <PreventTextOverflow>
                    <Typography type="caption" color="onSurfaceMedium">
                      {data.productSKU}
                    </Typography>
                  </PreventTextOverflow>
                  <TextDotSeparator
                    leftText={[
                      "label_pack",
                      "/",
                      "label_value",
                      "/",
                      "label_unit",
                    ]}
                    rightText={`${data.packageType} ${data.packageValue} ${data.packageUnit}`}
                  />
                  <TextDotSeparator
                    leftText={"label_vendor"}
                    rightText={data.vendor}
                  />
                </>
              )}
            </div>

            <div style={{ flexBasis: "206px", minWidth: "0px" }}>
              {isLoading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <>
                  <TextDotSeparator
                    leftText={"label_inventory_code"}
                    rightText={data.inventoryCode + ""}
                  />
                  <TextDotSeparator
                    leftText={"label_current_stock"}
                    rightText={String(data.totalStock) ?? "-"}
                  />
                  <TextDotSeparator
                    leftText="label_enough_for"
                    rightText={
                      // data.enoughFor ? parseISODuration(data.enoughFor) :
                      "-"
                    }
                  />
                </>
              )}
            </div>
          </div>

          <div style={{ display: "flex", gap: "26px", marginTop: "auto" }}>
            <div style={{ flexBasis: "206px", minWidth: "0px" }}></div>

            <div style={{ flexBasis: "206px", minWidth: "0px", flexGrow: 1 }}>
              {isLoading ? null : (
                <EditInventoryButton
                  initialValue={{
                    productId: data?.productId,
                    name: data?.productName,
                    inventoryCode: data?.inventoryCode,
                    brand: data?.vendor,
                    packaging: data?.packageType,
                    packageValue: data?.packageValue + "",
                    unitOfMeasurement: data?.packageUnit as UnitOfMeasurement,
                    sku: data?.productSKU,
                    base64Img: data?.productImage,
                    category:
                      CATEGORY[data?.machineCategory] ?? data?.machineCategory,
                    description: data?.productDescription,
                  }}
                />
              )}
              {editInventoryData && (
                <CreateOrEditInventoryModal
                  initialValues={editInventoryData}
                  onClose={() => setEditInventoryData(undefined)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: "1 1 0px",
          minHeight: "0px",
        }}
      >
        <ErrorBoundary FallbackComponent={Fallback}>
          <InventoryIdViewTable />
        </ErrorBoundary>
      </div>
    </div>
  );
}
