import Menu from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";
import * as React from "react";
import { GenerateNonAdminColumnsType } from "./useGenerateNonAdminColumns";
import { useTranslation } from "react-i18next";
import { IMachineInventoryWithPercentageData } from "./mapDataToTable";
import ChangeIngredientOption from "../ChangeIngredientOption";

type ActionMenuProps = GenerateNonAdminColumnsType & {
  data: IMachineInventoryWithPercentageData;
};

export function ActionMenu({ data, action, permission }: ActionMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id="machine-inventory-action-dropdown-button"
        className="h-9 box-border flex items-center justify-center cursor-pointer bg-white hover:bg-overlay-surface-04 border border-outline p-2.5 rounded"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        role="button"
        onClick={handleClick}
      >
        <Icon name="ChevronThick" color="primary" />
      </div>
      <Menu
        id="machine-inventory-action-dropdown-menu"
        aria-labelledby="machine inventory action dropdown"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {permission.isForthCKUser && (
          <MenuWithIcon
            onClick={() => {
              action.handleQuantityEdit(data);
              handleClose();
            }}
          >
            {t("action_edit_quantity")}
          </MenuWithIcon>
        )}

        <MenuWithIcon
          onClick={() => {
            action.handleMinMaxEdit(data);
            handleClose();
          }}
        >
          {t("action_update_min_max")}
        </MenuWithIcon>
        <MenuWithIcon onClick={handleClose}>
          <ChangeIngredientOption
            data={data}
            isEnableWorkOrderCreation={
              permission.isMachineEnableWorkOrderCreation
            }
            handleIngredientEdit={action.handleIngredientEdit}
            handleSwapInventoryCancel={action.handleSwapInventoryCancel}
          />
        </MenuWithIcon>
        <MenuWithIcon
          onClick={() => {
            action.handleActive(data);
            handleClose();
          }}
        >
          {t("action_active_slot")}
        </MenuWithIcon>
        <MenuWithIcon
          onClick={() => {
            action.handleDisable(data);
            handleClose();
          }}
        >
          {t("action_inactive_slot")}
        </MenuWithIcon>
      </Menu>
    </>
  );
}

type MenuWithIconProps = MenuItemProps & {
  children: React.ReactNode;
};
function MenuWithIcon({
  children,
  disabled,
  onClick,
  ...props
}: MenuWithIconProps) {
  console.log("props", props);
  return (
    <MenuItem
      {...props}
      className={clsx("gap-2 !py-3", props.className)}
      onClick={(e) => !disabled && onClick?.(e)}
    >
      <p className="text-on-surface-high font-main">{children}</p>
    </MenuItem>
  );
}
