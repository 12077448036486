import { ServiceZonesListHeader } from "containers/location/components/ServiceZonesHeader";
import * as React from "react";
import { ServiceZonesListTable } from "./table";

export default function ServiceZonesList() {
  return (
    <>
      <ServiceZonesListHeader/>
      <ServiceZonesListTable/>
    </>
  );
}
