import { Row } from "@tanstack/react-table";
import { clsx } from "clsx";
import * as React from "react";
import { CellComponent } from "./Cell";

type RowComponentProps<T> = {
  row: Row<T>;
  totalPinnedLeft: number;
  tableName: string;
};

function RowComponentInner<T>({
  row,
  totalPinnedLeft,
  tableName,
}: RowComponentProps<T>) {
  const visibleCells =  row.getVisibleCells();

  return (
    <div
      className={clsx(
        "grid min-h-[50px] w-full",
        row.getIsSelected() && "bg-primary-50"
      )}
      style={{
        gridTemplateColumns: "var(--columns-grid)",
      }}
    >
      {visibleCells.map((cell) => <CellComponent key={cell.id} cell={cell} tableName={tableName} totalPinnedLeft={totalPinnedLeft} />)}
    </div>
  );
}

const areEqual = <T,>(
  prevProps: RowComponentProps<T>,
  nextProps: RowComponentProps<T>
) => {
  // Prevent re-render if the row object is the same
  return prevProps.row.id === nextProps.row.id;
};

export const RowComponent = React.memo(RowComponentInner, areEqual);
