import * as React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { GetServiceZonesListQuery } from "gql/generated";


export function useColumnConfig() {
  const { t } = useTranslation("location", { keyPrefix: "serviceZonesList.table.column"});
  const columnHelper = createColumnHelper<GetServiceZonesListQuery["serviceZones2"]["edges"][number]["node"]>();
  return [
    columnHelper.accessor("friendlyId", {
      header: () => <>{t("service_zone_id")}</>,
      cell: (info) => <>{info.getValue()}</>,
      maxSize: 120,
      minSize: 30
    }),
    columnHelper.accessor("name", {
      header: () => <>{t("service_zone_name")}</>,
      cell: (info) => <>{info.getValue()}</>,
      enableColumnFilter: true,
      minSize: 30,
      meta: {
        columnFilter: {
          location: "primary",
          type: "text",
          placeholder: t("service_zone_name"),
          label: t("service_zone_name"),
        },
      },
    }),
    ];
}
