import * as React from "react";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  RowSelectionState,
  OnChangeFn,
  getGroupedRowModel,
} from "@tanstack/react-table";
import Checkbox from "components/Checkbox/Checkbox";
import { Filter } from "./filter/index";
import { InputValuesState } from "./inputState";
import { ColumnFiltersState } from "./types/columnMetaTypes";
import {
  GenerateTableColumnsProps,
  RowPerPageValue,
  RowSelectionType,
  TableV2Props,
} from "./types/tableTypes";
import { Pagination } from "./pagination";
import { TableComponent } from "./table";
import { debounce, kebabCase } from "lodash";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import { parseColumnFilterState } from "./setting/state";
import { useLocalStorage } from "react-use";
import { isNullish } from "utils/isNullish";
import { generateTableColumnFilterSettingKey, generateTableRowPerPageSettingKey } from "./setting";
import * as Sentry from "@sentry/react";
import { clsx } from "clsx";
import { TableContextProvider } from "./tableState";

export const ROW_SELECTION_ID = "rowSelection";

function useGenerateTableColumns<T>({
  columns,
  rowSelection,
}: GenerateTableColumnsProps<T>): ColumnDef<T>[] {
  return React.useMemo(() => {
    if (!rowSelection || !rowSelection.enable) return columns;

    const selectionColumn: ColumnDef<T> = {
      id: ROW_SELECTION_ID,
      header: ({ table }) => (
        <div className="flex items-center justify-center w-full">
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            indeterminate={table.getIsSomeRowsSelected()}
          />
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center justify-center w-full">
          <Checkbox
            checked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
          />
        </div>
      ),
      enableSorting: false,
      minSize: 40,
      size: 40,
      maxSize: 40,
      meta: {
        rowSelection: true,
      },
    };

    return [selectionColumn, ...columns];
  }, [columns, rowSelection]);
}

const EmptyArray = [];

export function TableV2<T>({
  className,
  customBodyRender,
  tableName,
  data,
  columns,
  pagination,
  isLoading,
  rowSelection = false,
  manualFiltering = true,
  manualSorting = true,
  onColumnFiltersChange,
  onSortingChange,
  onRowSelectionChange,
  sorting,
  rowSelectionState,
  debounceFilterTime = 300,
  columnPinning,
  defaultColumnSize,
}: TableV2Props<T>) {
  const formatTableName = kebabCase(tableName);
  const [setting, setSetting] = useLocalStorage(
    generateTableColumnFilterSettingKey(formatTableName)
  );
  const [rowPerPage] = useLocalStorage(
    generateTableRowPerPageSettingKey(formatTableName)
  );
  
  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2",
  });

  const [clientColumnFilters, setClientColumnFilters] =
    React.useState<ColumnFiltersState>([]);
  const [clientSorting, setClientSorting] = React.useState<SortingState>([]);
  const [rowSelectionClientState, setRowSelectionClientState] =
    React.useState<RowSelectionState>({});

  const debouncedOnColumnFiltersChange = React.useMemo(
    () =>
      onColumnFiltersChange &&
      debounce(onColumnFiltersChange, debounceFilterTime),
    [onColumnFiltersChange, debounceFilterTime]
  );

  // Cleanup the debounce function on component unmount
  React.useLayoutEffect(() => {
    try {
      if (!isNullish(setting)) {
        const state = parseColumnFilterState(setting);
        if (state.length === 0) return;
        setClientColumnFilters(state as ColumnFiltersState);
        onColumnFiltersChange(state as ColumnFiltersState);
      }

      return () => {
        debouncedOnColumnFiltersChange?.cancel();
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const handleChange: OnChangeFn<ColumnFiltersState> = (updater) => {
    setClientColumnFilters(updater); // Update the local state immediately
    debouncedOnColumnFiltersChange?.(updater); // Call the debounced onChange
    pagination?.onRowPerPageChange && pagination?.onRowPerPageChange(rowPerPage as RowPerPageValue || pagination.rowPerPage); // reset pagination and get current row per page from local storage
    // save filter to local storage if it enable
    // for now we will enable save-filter feature as default    
    const newFilter =
      typeof updater === "function" ? updater(clientColumnFilters) : updater;
    setSetting([...newFilter]);
  };

  const generatedColumns = useGenerateTableColumns({ columns, rowSelection });

  const table = useReactTable({
    data: data || EmptyArray,
    columns: generatedColumns,
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    manualFiltering,
    manualSorting,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: rowSelection ? rowSelection.getRowId : undefined,
    onRowSelectionChange: onRowSelectionChange || setRowSelectionClientState,
    onSortingChange: onSortingChange || setClientSorting,
    onColumnFiltersChange: handleChange,
    state: {
      sorting: sorting || clientSorting,
      rowSelection: rowSelectionState || rowSelectionClientState,
      columnFilters: clientColumnFilters,
      tableName: formatTableName,
      columnPinning: {
        left:  rowSelection && columnPinning ? [ROW_SELECTION_ID, ...columnPinning]: columnPinning
      }
    },
    defaultColumn: {
      size: defaultColumnSize,
      enableColumnFilter: false,
      enableSorting: false,
      meta: {
        columnFilter: {
          type: "text",
          location: "modal",
        },
      },
    },
  });

  return (
    <TableContextProvider>
      <div className={clsx("w-full flex flex-col", className)}>
        <div className="flex-1 flex flex-col bg-white rounded-lg border border-solid border-outline font-kanit overflow-hidden">
          <div className="flex w-full box-border ">
            <Filter table={table} />
          </div>
          {!customBodyRender && (
            <div className="flex-1 overflow-x-auto">
              <TableComponent
                table={table}
                isLoading={isLoading}
              />
              {!isLoading && table.getRowModel().rows.length === 0 && (
                <div className="flex flex-1 justify-center items-center h-[40vh]">
                  <div className="flex flex-col justify-center items-center">
                    <Icon
                      className="w-6 h-6"
                      name="Inbox"
                      color="onSurfaceDisabled"
                    />
                    <p className="text-h6 text-on-surface-disabled font-medium">
                      {t("label_not_found")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {customBodyRender && customBodyRender()}
        </div>
        {pagination && (
          <Pagination pagination={pagination} tableName={tableName} />
        )}
      </div>
    </TableContextProvider>
  );
}

export {
  createColumnHelper,
  ColumnFiltersState,
  SortingState,
  RowSelectionType,
  RowSelectionState,
  InputValuesState,
};
