import { flexRender, HeaderGroup } from "@tanstack/react-table";
import { clsx } from "clsx";
import * as React from "react";
import { getCommonPinningStyles } from "./getCommonPinningStyles";
import { tableV2Tracking } from "../tracking";
import { useTableContext } from "../tableState";
import { SortIcon } from "./SortIcon";
import Icon from "common/components/icon/Icon";

type HeaderRowInnerProps<T> = {
  headerGroup: HeaderGroup<T>;
  haveSubHeader: boolean;
  totalPinnedLeft: number;
  tableName: string;
};

export function HeaderRow<T>({
  headerGroup,
  haveSubHeader,
  totalPinnedLeft,
  tableName,
}: HeaderRowInnerProps<T>) {
  const { setActiveInPlaceFilterColumnId } = useTableContext();

  return (
    <div
      key={headerGroup.id}
      className={clsx("tr grid", haveSubHeader ? "h-[25px]" : "h-[50px]")}
      style={{
        alignItems: "stretch",
        gridTemplateColumns: `var(--header-grid-${headerGroup?.id})`
      }}
    >
      {headerGroup.headers.map((header) => {
        const { className, style } = getCommonPinningStyles({
          column: header.column,
          totalPinnedLeft,
        });
        if (header.column.columnDef.meta?.hide) return null;

        const columnRelativeDepth = header.depth - header.column.depth;

        if (columnRelativeDepth > 1) {
          return (
            <div
              key={header.id}
              className="bg-overlay-surface-04"
              style={{
                width: `calc(var(--header-${header?.id}-size) * 1px)`,
                minWidth: header.column.columnDef.minSize,
                maxWidth: header.column.columnDef.maxSize,
              }}
            />
          );
        }

        let rowSpan = 1;
        if (header.isPlaceholder) {
          const leafs = header.getLeafHeaders();
          rowSpan = leafs.length;
        }

        return (
          <div
            key={header.id}
            className={clsx(
              "h-full box-border flex items-center relative py-0 gap-1 bg-overlay-surface-04 text-left !border-outline border-solid text-on-surface-medium text-caption",
              !(header.column.columnDef.meta as any)?.rowSelection && "px-2",
              header.column.columnDef.meta?.className,
              header.column.columnDef.meta?.headerClassName,
              header.column.getCanSort() ? "cursor-pointer select-none" : "",
              className
            )}
            style={{
              height: haveSubHeader ? `${25*rowSpan}px`: "50px",
              minWidth: header.column.columnDef.minSize,
              maxWidth: header.column.columnDef.maxSize,
              ...style,
            }}
            onClick={() => {
              header.column.toggleSorting();
              tableV2Tracking({
                name: "table header cell clicked",
                properties: {
                  column_id: header.column.id,
                  can_sort: header.column.getCanSort(),
                  is_sorted: header.column.getIsSorted(),
                },
                tableName,
              });
            }}
          >
            <div className="flex items-center justify-between w-full">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {/* Sort Icon */}
              {(header.column.getCanSort() &&
                {
                  asc: <SortIcon canSort isSorted isSortedDesc={false} />,
                  desc: <SortIcon canSort isSorted isSortedDesc={true} />,
                }[header.column.getIsSorted() as string]) ??
                null}
              {!header.column.getIsSorted() && header.column.getCanSort() && (
                <SortIcon canSort isSorted={false} isSortedDesc={true} />
              )}
              {/* In-Place Filter Icon */}
              {header.column.columnDef?.enableColumnFilter &&
                header.column.columnDef?.meta?.columnFilter?.location ===
                  "in-place" && (
                  <div
                    onClick={() =>
                      setActiveInPlaceFilterColumnId(header.column.id)
                    }
                    className={clsx(
                      "group flex p-px cursor-pointer rounded-sm",
                      header.column.getFilterValue()
                        ? "bg-primary"
                        : "hover:bg-primary"
                    )}
                  >
                    <Icon
                      name="Filter2"
                      color={
                        header.column.getFilterValue()
                          ? "white"
                          : "onSurfaceMedium"
                      }
                      className={clsx(
                        "w-3 h-3",
                        header.column.getFilterValue()
                          ? ""
                          : "group-hover:hidden"
                      )}
                    />
                    {!header.column.getFilterValue() && (
                      <Icon
                        name="Filter2"
                        color="white"
                        className="w-3 h-3 hidden group-hover:block"
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
