import * as React from "react";
import { ColumnFiltersState, TableV2 } from "components/tableV2";
import { RowPerPageValue } from "components/tableV2/types/tableTypes";
import { CursorPagination, ServiceZoneFilter, useGetServiceZonesListQuery } from "gql/generated";
import { useColumnConfig } from "./useColumnConfig";

function mapFilterToServiceZonesListFilter(columnFilters: ColumnFiltersState): ServiceZoneFilter {
  const serviceZoneFilter: ServiceZoneFilter = {};
  columnFilters.forEach((filter) => {
   const {id, value} = filter; 
   switch (id) {
     case 'name':
       serviceZoneFilter.name = value.toString();
       break;
     default:
       break;
   }
  }
  );
    return serviceZoneFilter;
} 

export function ServiceZonesListTable() {
  const columns = useColumnConfig();
  const [rowPerPage, setRowPerPage] = React.useState<RowPerPageValue>(20);
  const [pagination, setPagination] = React.useState<Omit<CursorPagination, "sort">>({first: rowPerPage,});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const {data, isLoading, isFetching} = useGetServiceZonesListQuery({
    pagination: pagination, 
    filter: mapFilterToServiceZonesListFilter(columnFilters)
  });
  const resetPagination = (val: number) => setPagination({ first: val });
  const initialPagination: CursorPagination = {first: rowPerPage,};
   return (
    <TableV2
      tableName="Sales Group By Location Table"
      columns={columns}
      isLoading={isLoading || isFetching}
      data={data?.serviceZones2?.edges.map((edge) => (edge.node))}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={{
        rowPerPage: rowPerPage,
        onRowPerPageChange: (val) => {
          setRowPerPage(val);
          resetPagination(val);
        },
        hasNextPage: data?.serviceZones2?.pageInfo?.hasNextPage || false,
        hasPreviousPage: data?.serviceZones2?.pageInfo?.hasPreviousPage || false,
        onNextPage: () =>
          setPagination(() => ({
            after: data?.serviceZones2?.pageInfo?.endCursor,
            first: rowPerPage,
            last: undefined,
            before: undefined,
          })),
        onPreviousPage: () =>
          setPagination(() => ({
            first: undefined,
            after: undefined,
            last: rowPerPage,
            before:data?.serviceZones2?.pageInfo?.startCursor,
          })),
        resetPagination: () => setPagination(initialPagination),
      }}
    />
  );
}
