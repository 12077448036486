import * as React from "react";

type SkeletonCellProps = {
  minSize?: number;
  maxSize?: number;
};

function SkeletonCellInner({ maxSize, minSize }: SkeletonCellProps) {
  return (
    <div
      className="box-border px-2.5 py-4 !border-t !border-x-0 !border-outline border-solid"
      style={{
        minWidth: minSize,
        maxWidth: maxSize,
      }}
    >
      <div className="h-4 animate-pulse bg-gray-200 rounded-full w-full" />
    </div>
  );
}

export const SkeletonCell = React.memo(SkeletonCellInner);