import { Cell, flexRender } from "@tanstack/react-table";
import { clsx } from "clsx";
import * as React from "react";
import { getCommonPinningStyles } from "./getCommonPinningStyles";
import { tableV2Tracking } from "../tracking";



type CellComponentProps<T> = {
  cell: Cell<T, unknown>;
  totalPinnedLeft: number;
  tableName: string;
};

export function CellComponent<T> ({ cell, totalPinnedLeft, tableName }: CellComponentProps<T>) {
  const { className, style } = getCommonPinningStyles({
    column: cell.column,
    totalPinnedLeft,
  });
  if (cell.column.columnDef.meta?.hide) return null;

  return (
    <div
      className={clsx(
        "relative box-border bg-white py-2 !border-t !border-outline border-solid text-body2 text-on-surface-high",
        !cell.column.columnDef.meta?.rowSelection && "px-2",
        cell.column.columnDef.meta?.className,
        cell.column.columnDef.meta?.bodyClassName,
        className
      )}
      style={{
        minWidth: cell.column.columnDef.minSize,
        maxWidth: cell.column.columnDef.maxSize,
        ...style,
      }}
      onClick={() =>
        tableV2Tracking({
          name: "table body cell clicked",
          properties: {
            cell_id: cell.id,
            column_id: cell.column.id,
          },
          tableName,
        })
      }
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );
}