import * as React from 'react';
import { useState } from 'react';
import Autocomplete from 'components/Autocomplete';
import MuiModal from 'components/Modal/MuiModal';

import Typography from 'components/Typography';
import Button from 'components/Button';
import { IEditRefillZone } from 'machines/interfaces';
import {
  useGetRefillZonesByOrgIdQuery,
  useBatchUpdateMachineRefillZoneMutation,
} from 'gql/generated';
import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';

import * as styles from './ChangeZoneModal.module.scss';
import { toasti18n } from 'utils/toast';
import { queryClient } from 'index';

interface IChangeZoneModalProps {
  selectedMachine: Array<string>;
  isOpen: boolean;
  onClose: () => void;
}

export function ChangeZoneModal({
  selectedMachine,
  isOpen,
  onClose,
}: IChangeZoneModalProps): JSX.Element {
  const { data: userInfo } = useGetCurrentUser();
  const {
    mutate: usePostBatchUpdateMachineRefillZone,
    isLoading: isBatchUpdateLoading,
  } = useBatchUpdateMachineRefillZoneMutation({
    onError: (error) => {
      toasti18n.error(error as Error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['machines', { extraFields: true }]);
      toasti18n.success();
      onClose();
    },
  });

  const [zoneId, setZoneId] = useState('');
  const { data: refillZonesByOrgIdQuery, isLoading: isrefillZoneLoading } =
    useGetRefillZonesByOrgIdQuery({ organisationId: userInfo.orgId }, {
      staleTime: 1000 * 60 * 5
    });
  const refillZones = refillZonesByOrgIdQuery?.refillZones;

  function editZone({ machineIds, refillZoneId }: IEditRefillZone) {
    usePostBatchUpdateMachineRefillZone({ machineIds, refillZoneId });
  }

  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      minWidth="416px"
      minHeight="204px"
      padding="24px 32px"
    >
      <div className={styles.ChangeZone}>
        <div>
          <Typography translate type="headline-6">
            label_edit_zone
          </Typography>
        </div>

        <div className={styles.EditZoneContent}>
          <Autocomplete
            value={zoneId}
            onChange={(zone) => setZoneId(zone)}
            id="refillZone"
            label="label_refill_zone"
            options={
              isrefillZoneLoading
                ? []
                : refillZones.map((zone) => {
                    return {
                      label: zone.friendlyId,
                      value: zone.id,
                    };
                  })
            }
            required
          />
        </div>

        <div className={styles.ButtonContainer}>
          <Button
            disabled={isBatchUpdateLoading}
            type="secondary"
            onClick={onClose}
          >
            {'action_cancel'}
          </Button>
          <Button
            loading={isBatchUpdateLoading}
            onClick={() =>
              editZone({ machineIds: selectedMachine, refillZoneId: zoneId })
            }
            className={styles.save}
            type="primary"
            disabled={zoneId === ''}
          >
            {'action_save'}
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};
