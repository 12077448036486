import ViewNav from "common/components/viewnav/ViewNav";
import * as React from "react";
import { useTranslation } from "react-i18next";

export function ServiceZonesListHeader(): JSX.Element {
  const { t } = useTranslation("location");
  return (
    <>
      <div className="flex gap-4 items-center mb-5">
          <h2 className="flex-1">
            <span>
              {`${t("title")} / `}
            </span>
            <span className="text-primary">
              {t("serviceZonesList.title")}
            </span>
          </h2>
            <ViewNav
              options={[
                {
                  icon: "Location",
                  to: "/location/list",
                },
                {
                  icon: "Service",
                  to: "/location/service-zones",
                },
              ]}
            />
        </div>
    </>
  );
}
