export const CATEGORY = {
  COFFEE: "COFFEE",
  SPIRAL: "SPIRAL",
  BEVERAGE: "BEVERAGE",
} as const;

export type Category = typeof CATEGORY[keyof typeof CATEGORY];

const CATEGORY_TRANSLATIONS = {
  COFFEE: "label_coffee",
  SPIRAL: "label_spiral",
  BEVERAGE: "label_beverage",
} as const;

export function getCategoryTranslation(
  key: Category | keyof typeof CATEGORY | (string & {})
): undefined | string {
  return CATEGORY_TRANSLATIONS[key];
}
