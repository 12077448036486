import * as React from "react";
import { useLocation } from "react-router-dom";

import Icon from "common/components/icon/Icon";
import SideNavButton, {
  ISideNavButtonProps,
} from "common/components/sidenavbutton/SideNavButton";

import { isMobile, isTablet } from "react-device-detect";

import * as styles from "./SideNav.module.scss";
import { useIsAdmin, useIsAdminContract } from "utils/user-role";
import { ContractSideNav } from "containers/contract";
import { NewOperationSideNav } from "containers/new-operations/Components";
import { MaintenaceSideNav } from "containers/maintenance";

function SideNav(): JSX.Element {
  const { pathname } = useLocation();

  const commonMenuOptions = (
    <>
      <SideNavButton
        to="/dashboard"
        translationKey="workspace_dashboard"
        icon="Grid"
        active={pathname.includes("/dashboard")}
      />

      <SideNavButton
        to="/machines"
        translationKey="workspace_machines"
        icon="Machine"
        active={pathname.includes("/machines")}
      />

      <SideNavButton
        to="/transactions/group-by-none"
        translationKey="workspace_transactions"
        icon="CreditCard"
        active={pathname.startsWith("/transactions")}
      />

      <SideNavButton
        to="/warehousing"
        translationKey="workspace_warehousing"
        icon="Warehouse"
        active={pathname.includes("/warehousing")}
      />

      <ContractSideNav />

      <MaintenaceSideNav />

      <SideNavButton
        to="/service"
        translationKey="workspace_service"
        icon="Service"
        active={pathname.includes("/service/")}
      />

      <NewOperationSideNav />

      <SideNavButton
        to="/location"
        translationKey="workspace_location"
        icon="Location"
        active={pathname.startsWith("/location")}
      />
    </>
  );

  return (
    <>
      {!isMobile && !isTablet && (
        <div>
          <div className={[styles.SideNav, styles.SideNavDesktop].join(" ")}>
            <div className={styles.menu}>
              <div className={styles.logo}>
                <Icon name="VendiiSymbol" />
              </div>
              {commonMenuOptions}
            </div>

            <div className={styles.footer}>
              <SettingsSideNav />
            </div>
          </div>
        </div>
      )}

      {isTablet && (
        <div>
          <div className={[styles.SideNav, styles.SideNavDesktop].join(" ")}>
            <div className={styles.menu}>
              <div className={styles.logo}>
                <Icon name="VendiiSymbol" />
              </div>
              {commonMenuOptions}
            </div>

            <div className={styles.footer}>
              <SettingsSideNav />
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div>
          <div className={[styles.SideNav, styles.SideNavMobile].join(" ")}>
            {commonMenuOptions}
            <SettingsSideNav />
          </div>
        </div>
      )}
    </>
  );
}

function SettingsSideNav(): JSX.Element {
  const isUserAdmin = useIsAdmin() || useIsAdminContract();
  const { pathname } = useLocation();

  const subNavItems: ISideNavButtonProps["subNavigation"] = [
    {
      to: "/settings/usersetting",
      translationKey: "label_user_setting",

      active: pathname.includes("/settings/usersetting"),
      icon: "User",
    },
    {
      to: "/settings/change-password",
      translationKey: "label_change_password_setting",

      active: pathname.includes("/settings/change-password"),
      icon: "Password",
    },
  ];

  if (isUserAdmin) {
    subNavItems.push(
      {
        to: "/settings/organisation",
        translationKey: "label_organisation",
        active: pathname.includes("/settings/organisation"),
        icon: "Organisation",
      },
      {
        to: "/settings/team",
        translationKey: "label_team",
        active: pathname.includes("/settings/team"),
        icon: "Team",
      }
    );
  }

  return (
    <SideNavButton
      to="/settings"
      icon="Sliders"
      active={pathname.includes("/settings")}
      subNavigation={subNavItems}
    />
  );
}

export default SideNav;
