import * as React from "react";
import { Table } from "@tanstack/react-table";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { SkeletonCell } from "./SkeletonCell";
import { InputStateType } from "../types/tableTypes";
import { RowComponent } from "./Row";
import { HeaderRow } from "./HeaderRow";

type TableProps<T> = InputStateType & {
  table: Table<T>;
  isLoading: boolean;
};

export function TableComponent<T>({ table, isLoading }: TableProps<T>) {
  const listRef = React.useRef<HTMLDivElement | null>(null);
  const tableName = table.getState().tableName;
  const totalPinnedLeft = table
    .getAllLeafColumns()
    .filter((col) => col.getIsPinned() && col.getIsVisible()).length;
  const headerGroups = table.getHeaderGroups();

  const columnSizeVars = React.useMemo(() => {
    const cssVars: { [key: string]: string } = {};

    for (let i = 0; i < headerGroups.length; i++) {
      const gridTemplateColumnsVar = headerGroups[i].headers
        .filter((header) => !header.column.columnDef.meta.hide)
        .map((header) =>
          header.column.columnDef.meta?.autoColumnWidth
            ? "1fr"
            : `${header.getSize()}px`
        )
        .join(" ");
      cssVars[`--header-grid-${headerGroups[i].id}`] = gridTemplateColumnsVar;
    }

    const gridTemplateColumnsForVisibleColumns = table
      .getVisibleLeafColumns()
      .filter((column) => !column.columnDef.meta.hide)
      .map((column) =>
        column.columnDef.meta?.autoColumnWidth ? "1fr" : `${column.getSize()}px`
      )
      .join(" ");
    cssVars["--columns-grid"] = gridTemplateColumnsForVisibleColumns;

    return cssVars;
  }, [headerGroups, table]);

  const rowVirtualizer = useWindowVirtualizer({
    count: isLoading ? 20 : table.getRowModel().rows.length,
    estimateSize: () => 50, // Estimate row height
    overscan: 10,
    scrollMargin: listRef.current?.offsetTop ?? 0,
  });

  return (
    <div
      ref={listRef}
      className="table-auto border-separate border-spacing-0 overflow-hidden min-w-full font-kanit max-w-full overflow-x-auto overflow-y-hidden border-t !border-outline border-solid"
      style={{
        ...columnSizeVars,
        width: table.getTotalSize(),
      }}
    >
      <div className="relative thead w-full">
        {headerGroups.map((headerGroup) => {
          return (
            <HeaderRow
              key={headerGroup.id}
              headerGroup={headerGroup}
              haveSubHeader={headerGroups.length !== 1}
              totalPinnedLeft={totalPinnedLeft}
              tableName={tableName}
            />
          );
        })}
      </div>
      <div
        className="tbody"
        style={{
          position: "relative",
          height: isLoading ? "1000px" : `${rowVirtualizer.getTotalSize()}px`, // Set container height based on virtualizer
        }}
      >
        {isLoading &&
          Array(20)
            .fill("")
            .map((_, key) => (
              <div
                key={key}
                className="grid"
                style={{
                  gridTemplateColumns: "var(--columns-grid)",
                }}
              >
                {table
                  .getAllLeafColumns()
                  .filter((col) => !col.columnDef.meta.hide)
                  .map((column) => (
                    <SkeletonCell
                      key={column.id}
                      maxSize={column.columnDef.maxSize}
                      minSize={column.columnDef.minSize}
                    />
                  ))}
              </div>
            ))}
        {!isLoading &&
          rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = table.getRowModel().rows[virtualRow.index];
            if (!row) return null;

            return (
              <div
                key={virtualRow.key.toString()}
                data-index={virtualRow.index}
                ref={(node) => node && rowVirtualizer.measureElement(node)}
                style={{
                  position: "absolute",
                  left: 0,
                  top: `${virtualRow.start - rowVirtualizer.options.scrollMargin}px`,
                  width: "100%",
                  gridTemplateColumns: "var(--columns-grid)",
                }}
              >
                <RowComponent
                  key={String(row.getIsSelected())}
                  row={row}
                  tableName={tableName}
                  totalPinnedLeft={totalPinnedLeft}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
