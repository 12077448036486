import { ColumnFiltersState } from "components/tableV2";
import { LocationFilter, LocationType} from "gql/generated";

export function mapColumnFilterToLocationFilter(columnFilters: ColumnFiltersState): LocationFilter {
  const locationFilter: LocationFilter = {};

  columnFilters.forEach((filter) => {
    const { id, value } = filter;

    switch (id) {
      case 'friendlyId':
        locationFilter.friendlyId = Array.isArray(value) ? value[0] : value;
        break;
      case 'locationName':
        locationFilter.locationName = value as string;
        break;
      case 'locationType':
        locationFilter.locationType = [value as string] as LocationType[];
        break;
      case 'machineId':
        locationFilter.machineId = value as string;
        break;
      case 'refillZoneName':
        locationFilter.refillZoneName = value as string;
        break;
      case 'serviceZoneName':
        locationFilter.serviceZoneName = value as string;
        break;
      default:
        break;
    }
  });

  return locationFilter;
}
